import type { EventData, SocketQuestData } from "@/types";

const useQuestsSocket = () => {
	const { open } = useFunrizeModals();
	const { add, remove } = useSockets();
	const { exitFullscreen } = useFullscreenToggle();

	const { data: questPageData, refresh: refreshQuestPageData } = useGetPageQuestData({ immediate: false });
	const { data: questData, refresh: refreshQuestData } = useQuestData({ immediate: false });

	const lastTaskCompleted = computed(() => {
		const allTasks = [...questData.value?.dayInfo?.tasks];
		return allTasks?.length > 0 && !!allTasks[allTasks?.length - 1]?.isCompleted;
	});

	const updateTaskState = (task: SocketQuestData, type: "complete" | "progress") => {
		const currentTask = questData.value?.dayInfo?.tasks?.[task.taskIndex];
		const currentTaskPage = questPageData.value?.playerData?.days?.[task.dayIndex]?.tasks?.[task.taskIndex];

		if (currentTask) {
			if (type === "complete") {
				currentTask.isCompleted = true;
			}
			if (type === "progress") {
				currentTask.progress = task.progress;
				currentTask.repeat = task.repeat;
			}
			if (task.executionTimeData) {
				currentTask.executionTimeData = task.executionTimeData;
			}
		}

		if (currentTaskPage) {
			if (type === "complete") {
				currentTaskPage.isCompleted = true;
			}
			if (type === "progress") {
				currentTaskPage.progress = task.progress;
				currentTaskPage.repeat = task.repeat;
			}
			if (task.executionTimeData) {
				currentTaskPage.executionTimeData = task.executionTimeData;
			}
		}
	};

	add("questPoints", ({ action, data }: EventData<SocketQuestData>) => {
		if (action === "taskCompleted") {
			exitFullscreen();
			updateTaskState(data, "complete");

			if (questData.value?.questInfo?.type === "advent") {
				open("LazyOModalAdventCalendarCompleted", { ...data });
			} else if (questData.value?.questInfo?.type === "fp_challenge") {
				if (lastTaskCompleted.value) {
					open("LazyOModalFreeSpinCongrats", { questFinished: true, data });
				} else {
					open("LazyOModalFreeSpinCongrats", { data });
				}
			} else {
				open("LazyOModalQuestCompletedMission", { ...data });
			}
			setTimeout(() => {
				refreshQuestPageData();
				refreshQuestData();
			}, 1000);
		}

		if (action === "taskProgress") {
			updateTaskState(data, "progress");
		}

		if (
			action === "finished" ||
			action === "started" ||
			action === "dayFinished" ||
			action === "availableByRankLeague"
		) {
			refreshQuestPageData();
			refreshQuestData();
		}
	});

	onUnmounted(() => {
		remove("questPoints");
	});
};

export default useQuestsSocket;
